@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ballet:opsz@16..72&family=Cedarville+Cursive&family=Inspiration&family=Island+Moments&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ballet:opsz@16..72&family=Cedarville+Cursive&family=Inspiration&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mate:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');

.font-fantasy {
  font-family: 'Hurricane', cursive;
}

.font-logo {
  font-family: 'Inspiration', cursive; 
}

.font-header {
  font-family: 'Hurricane',swap;
}

.font-select {
  font-family: 'Hurricane', cursive;
}

.font-text{
  font-family: 'Mate', regular;
}
.overflow-hidden {
  overflow: hidden;
}